<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mb-5">
                                <label>{{$t('balance_sheet.financial_year')}}</label>
                                <multiselect v-model="financial_year"
                                             :placeholder="$t('balance_sheet.financial_year')"
                                             label="name"
                                             track-by="id"
                                             :options="financial_years_list"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <!-- End Filter -->
        <div class="card card-custom">
            <div class="card-body">
                <div class="mt-3">
                    <div class="row mb-10">
                        <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                            <h4 class="text-center">{{ $t('balance_sheet.balance_sheet')}}</h4>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-12" v-if="data_report_details">
                            <div class="table-responsive">
                                <div class="row justify-content-end p-4">
                                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                        <download-excel
                                                class="dropdown-item"
                                                :fetch="accountingDetailsExport"
                                                :fields="json_fields_details"
                                                :name="'income_report.xls'">
                                            <i class="la la-file-excel"></i>{{$t('excel')}}
                                        </download-excel>
                                        <button class="dropdown-item" @click="printData('summaryTableDetails')">
                                            <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                        </button>
                                    </b-dropdown>
                                </div>
                                <div class="row" id="summaryTableDetails">
                                    <div class="col-md-6" v-for="(row) in data_report_details" :key="row.id">
                                        <table class="table table-bordered">
                                            <tbody v-html="getHtmlContent(row)">

                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--end::supplier-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "Index-balance-sheet",
        data() {
            return {
                mainRoute: '/reports/accounting/balance-sheet',
                mainRouteDependency: 'base/dependency',

                financial_years_list: [],
                data_report_details: [],
                details_total_sum: null,

                filters: {
                    financial_year_id: null,
                },
                financial_year: null,
                currency_name: null,
                HTMLData: "",
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                exportData: [],
            }
        },
        computed: {
            json_fields_details: function () {
                let fields = {};

                fields[this.$t('balance_sheet.label')] = 'label';
                fields[this.$t('balance_sheet.amount') + ` (${this.currency_name})`] = 'amount';

                return fields;
            },


        },
        watch: {
            financial_year: function (val) {
                if (val) {
                    this.filters.financial_year_id = val.id;
                } else {
                    this.filters.financial_year_id = null;
                }
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.balance_sheet")}]);
            this.getFinancialYears();
            this.getAuthUser();
        },
        methods: {
            accountingDetailsExport() {
                this.setReportLog('excel', 'balance sheet');
                let _footer = {
                    label: this.$t('balance_sheet.total') + ` (${this.currency_name})`,
                    amount: 0,
                };
                this.exportData = [];
                this.data_report_details.forEach((row) => {
                    this.customData(row);
                });
                this.exportData.push(_footer);

                return this.exportData;
            },
            customData(data_list) {
                this.exportData.push({
                    label: data_list.label + ` (${this.currency_name})`,
                    amount: (data_list.has_children ? this.$t('balance_balances_review.subtotal') + ': ' : '') + data_list.amount,
                });
                if (data_list.has_children) {
                    data_list.children.forEach((row) => {
                        this.customData(row);
                    });
                }
            },
            doFilter() {
                this.getReportDetails();
            },
            resetFilter() {
                this.filters.financial_year_id = null;
                this.financial_year = null;

            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },
            getFinancialYears(filter = null) {
                // if (filter && filter.length >= 2)
                ApiService.get(this.mainRouteDependency + "/financial_years", {params: {name: filter}}).then((response) => {
                    this.financial_years_list = response.data.data.map((row) => {
                        return {id: row.id, name: row.start_date + ' - ' + row.end_date};
                    });
                });
            },

            getReportDetails() {
                ApiService.get(this.mainRoute, {params: {...this.filters}}).then((response) => {
                    this.data_report_details = response.data.data.data;
                    this.details_total_sum = response.data.data.total_amount;
                });
            },
            getHtmlContent(_data) {
                let _margin = "";
                _margin = "padding-left: " + (_data.code ? (_data.code.length * 4) : 0) + 'px';

                let content = "<tr><th style='" + _margin + "'>" + _data.label + "</th><td>" + (_data.has_children ? this.$t('balance_sheet.subtotal') + ': ' : '') + _data.amount + ` (${this.currency_name})` + "</td></tr>";
                if (_data.has_children) {
                    _data.children.forEach((row) => {
                        content += this.getHtmlContent(row);
                    })
                }
                return content;
            },
            printData(tableId) {
                this.setReportLog('pdf', 'balance sheet');

                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        }
    }
</script>

<style scoped>

</style>